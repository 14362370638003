import React from 'react';

const ContentEnrichAndRestructureContent = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1834 25.3657C9.01582 25.3657 6.67456 20.9066 6.67456 18.0214C6.67456 16.6057 7.26511 15.2481 8.3163 14.247C9.36749 13.246 10.7932 12.6836 12.2798 12.6836C13.7664 12.6836 15.1921 13.246 16.2433 14.247C17.2945 15.2481 17.8851 16.6057 17.8851 18.0214C17.8024 20.9066 15.3785 25.3657 12.1834 25.3657ZM12.1834 14.0082C11.0679 14.0151 10.0003 14.4401 9.21152 15.1913C8.42276 15.9424 7.97636 16.9591 7.96914 18.0214C7.96914 20.2771 9.95232 24.0542 12.1834 24.0542C14.4145 24.0542 16.4252 20.2771 16.4252 18.0214C16.3825 16.9776 15.9169 15.9903 15.1259 15.2665C14.335 14.5427 13.2803 14.1387 12.1834 14.1395V14.0082Z"
      fill="#00FFFF"
    />
    <path
      d="M22.2094 33.3895H1.68894C1.50631 33.3895 1.33121 33.3203 1.20207 33.1974C1.07293 33.0744 1.00033 32.9077 1.00033 32.7338V28.4321C0.995581 28.2891 1.0421 28.1492 1.13222 28.0347C1.22234 27.9203 1.35074 27.8383 1.49619 27.8026L9.53905 25.7045V23.9995C9.53891 23.83 9.60772 23.6671 9.73096 23.5448C9.8542 23.4226 10.0224 23.3505 10.2002 23.3438C10.378 23.3505 10.5461 23.4226 10.6693 23.5448C10.7925 23.6671 10.8613 23.83 10.8612 23.9995V26.1764C10.861 26.3251 10.8097 26.4698 10.7152 26.5882C10.6207 26.7065 10.4882 26.7921 10.3379 26.8321L2.32248 28.9306V32.078H21.4657V28.9044L13.7258 26.7537C13.585 26.7104 13.4617 26.6266 13.3729 26.5139C13.2842 26.4012 13.2342 26.2647 13.23 26.1239V24.052C13.2298 23.8825 13.2986 23.7196 13.4219 23.5973C13.5451 23.4751 13.7133 23.403 13.8911 23.3963C14.0689 23.403 14.2371 23.4751 14.3603 23.5973C14.4835 23.7196 14.5523 23.8825 14.5521 24.052V25.6257L22.292 27.7764C22.4328 27.8197 22.5561 27.9035 22.6449 28.0162C22.7337 28.1289 22.7836 28.265 22.7878 28.4058V32.7338C22.7899 32.8909 22.7328 33.0437 22.6266 33.1641C22.5204 33.2844 22.3723 33.3643 22.2094 33.3895Z"
      fill="#00FFFF"
    />
    <path
      d="M31.0236 43.724C30.8409 43.724 30.6658 43.6549 30.5366 43.5319C30.4075 43.4089 30.335 43.2422 30.335 43.0683V5.42917C30.335 5.25526 30.4075 5.0882 30.5366 4.96523C30.6658 4.84225 30.8409 4.77344 31.0236 4.77344C31.2062 4.77344 31.3813 4.84225 31.5104 4.96523C31.6396 5.0882 31.7122 5.25526 31.7122 5.42917V42.9895C31.7239 43.082 31.7148 43.1757 31.6855 43.2645C31.6562 43.3534 31.6074 43.4355 31.5422 43.505C31.4771 43.5745 31.397 43.6299 31.3076 43.6676C31.2182 43.7054 31.1214 43.7247 31.0236 43.724Z"
      fill="#FCFCFD"
    />
    <path
      d="M31.0237 24.6279H20.4742C20.2916 24.6279 20.1165 24.5587 19.9874 24.4358C19.8582 24.3128 19.7856 24.1461 19.7856 23.9721C19.7856 23.7982 19.8582 23.6315 19.9874 23.5085C20.1165 23.3855 20.2916 23.3164 20.4742 23.3164H31.0237C31.2064 23.3164 31.3815 23.3855 31.5106 23.5085C31.6397 23.6315 31.7123 23.7982 31.7123 23.9721C31.7123 24.1461 31.6397 24.3128 31.5106 24.4358C31.3815 24.5587 31.2064 24.6279 31.0237 24.6279Z"
      fill="#FCFCFD"
    />
    <path
      d="M37.3311 43.7255H31.0236C30.8409 43.7255 30.6658 43.6564 30.5366 43.5334C30.4075 43.4105 30.335 43.2437 30.335 43.0698C30.335 42.8959 30.4075 42.7291 30.5366 42.6062C30.6658 42.4832 30.8409 42.4141 31.0236 42.4141H37.3311C37.5138 42.4141 37.6889 42.4832 37.8181 42.6062C37.9472 42.7291 38.0197 42.8959 38.0197 43.0698C38.0197 43.2437 37.9472 43.4105 37.8181 43.5334C37.6889 43.6564 37.5138 43.7255 37.3311 43.7255Z"
      fill="#FCFCFD"
    />
    <path
      d="M37.3311 24.6279H31.0236C30.8409 24.6279 30.6658 24.5587 30.5366 24.4358C30.4075 24.3128 30.335 24.1461 30.335 23.9721C30.335 23.7982 30.4075 23.6315 30.5366 23.5085C30.6658 23.3855 30.8409 23.3164 31.0236 23.3164H37.3311C37.5138 23.3164 37.6889 23.3855 37.8181 23.5085C37.9472 23.6315 38.0197 23.7982 38.0197 23.9721C38.0197 24.1461 37.9472 24.3128 37.8181 24.4358C37.6889 24.5587 37.5138 24.6279 37.3311 24.6279Z"
      fill="#FCFCFD"
    />
    <path
      d="M37.331 5.58738H31.0235C30.9234 5.60672 30.8202 5.60463 30.7211 5.5813C30.622 5.55797 30.5296 5.51409 30.4506 5.45258C30.3716 5.39108 30.3079 5.3137 30.2642 5.2259C30.2204 5.13809 30.1978 5.04195 30.1978 4.94478C30.1978 4.8476 30.2204 4.75178 30.2642 4.66397C30.3079 4.57617 30.3716 4.49847 30.4506 4.43697C30.5296 4.37547 30.622 4.33158 30.7211 4.30825C30.8202 4.28492 30.9234 4.28283 31.0235 4.30217H37.331C37.431 4.28283 37.5343 4.28492 37.6334 4.30825C37.7324 4.33158 37.8249 4.37547 37.9039 4.43697C37.9829 4.49847 38.0465 4.57617 38.0902 4.66397C38.134 4.75178 38.1567 4.8476 38.1567 4.94478C38.1567 5.04195 38.134 5.13809 38.0902 5.2259C38.0465 5.3137 37.9829 5.39108 37.9039 5.45258C37.8249 5.51409 37.7324 5.55797 37.6334 5.5813C37.5343 5.60463 37.431 5.60672 37.331 5.58738Z"
      fill="#FCFCFD"
    />
    <path
      d="M41.8209 47.9991C40.8003 47.999 39.8025 47.7115 38.9528 47.173C38.1031 46.6346 37.4395 45.8692 37.0453 44.9727C36.651 44.0763 36.5437 43.0887 36.7369 42.1343C36.9301 41.18 37.4152 40.3014 38.1311 39.6087C38.8471 38.9161 39.7621 38.4403 40.761 38.2412C41.76 38.0422 42.7985 38.1288 43.746 38.49C44.6936 38.8512 45.5078 39.471 46.0866 40.2715C46.6654 41.072 46.983 42.0174 46.9993 42.9892C47.0102 43.6434 46.8844 44.293 46.6291 44.9004C46.3737 45.5078 45.994 46.0606 45.5121 46.5269C45.0302 46.9932 44.4556 47.3635 43.8219 47.6162C43.1881 47.8689 42.508 47.9992 41.8209 47.9991ZM41.8209 39.4746C41.0668 39.4694 40.3282 39.6779 39.6989 40.0736C39.0696 40.4693 38.5782 41.0342 38.287 41.6966C37.9959 42.3591 37.9183 43.0892 38.0641 43.7938C38.2099 44.4984 38.5724 45.1457 39.1057 45.6534C39.6389 46.1612 40.3188 46.5065 41.0587 46.6454C41.7986 46.7842 42.5651 46.7103 43.2608 46.4331C43.9564 46.1559 44.5498 45.6879 44.9654 45.0886C45.381 44.4894 45.5999 43.7861 45.5945 43.068C45.5945 42.1149 45.197 41.2009 44.4893 40.527C43.7816 39.8531 42.8217 39.4746 41.8209 39.4746Z"
      fill="#FCFCFD"
    />
    <path
      d="M41.821 28.9325C40.7957 28.9377 39.7919 28.6528 38.9367 28.1141C38.0815 27.5755 37.4135 26.8074 37.0174 25.9068C36.6212 25.0062 36.5147 24.0138 36.7114 23.0556C36.9081 22.0973 37.3991 21.2161 38.1221 20.5239C38.8452 19.8316 39.7679 19.3594 40.7731 19.167C41.7783 18.9746 42.8209 19.0708 43.7688 19.4433C44.7166 19.8157 45.5269 20.4476 46.0972 21.259C46.6674 22.0705 46.9718 23.025 46.9718 24.0014C46.9718 25.3047 46.4301 26.5549 45.4649 27.4789C44.4997 28.4029 43.1896 28.9256 41.821 28.9325ZM41.821 20.408C41.0669 20.4028 40.3283 20.6113 39.699 21.007C39.0697 21.4028 38.5783 21.9679 38.2872 22.6304C37.996 23.2928 37.9184 24.0227 38.0642 24.7273C38.21 25.4318 38.5726 26.0791 39.1058 26.5869C39.639 27.0947 40.3189 27.44 41.0588 27.5788C41.7987 27.7176 42.5652 27.6437 43.2609 27.3665C43.9566 27.0893 44.5499 26.6213 44.9655 26.0221C45.3811 25.4228 45.6 24.7195 45.5946 24.0014C45.5946 23.0484 45.1971 22.1343 44.4894 21.4604C43.7817 20.7865 42.8219 20.408 41.821 20.408Z"
      fill="#FCFCFD"
    />
    <path
      d="M41.821 9.86222C40.7957 9.86741 39.7919 9.58251 38.9367 9.04383C38.0815 8.50516 37.4135 7.73707 37.0174 6.83649C36.6212 5.93592 36.5147 4.94353 36.7114 3.98527C36.9081 3.027 37.3991 2.14584 38.1221 1.45358C38.8452 0.761323 39.7679 0.289049 40.7731 0.0966446C41.7783 -0.0957595 42.8209 0.000520492 43.7688 0.372962C44.7166 0.745404 45.5269 1.37724 46.0972 2.18872C46.6674 3.0002 46.9718 3.95469 46.9718 4.93109C46.9718 6.23437 46.4301 7.48458 45.4649 8.40859C44.4997 9.33261 43.1896 9.85529 41.821 9.86222ZM41.821 1.33767C41.0669 1.33249 40.3283 1.54101 39.699 1.93674C39.0697 2.33247 38.5783 2.8976 38.2872 3.56006C37.996 4.22253 37.9184 4.95236 38.0642 5.65694C38.21 6.36152 38.5726 7.00909 39.1058 7.51688C39.639 8.02467 40.3189 8.36966 41.0588 8.50849C41.7987 8.64732 42.5652 8.57341 43.2609 8.29621C43.9566 8.019 44.5499 7.55101 44.9655 6.95176C45.3811 6.35251 45.6 5.64919 45.5946 4.93109C45.5874 3.98017 45.1875 3.07021 44.4814 2.3978C43.7753 1.72538 42.8196 1.34454 41.821 1.33767Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default ContentEnrichAndRestructureContent;
